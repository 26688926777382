


















































import { Component, Prop, Vue, Ref } from 'vue-property-decorator'
import { getDefaultFuseOptions } from '@/utils'
import { multiSearch } from '@/utils/Common'
import { Tag } from '@tada-team/tdproto-ts'
import { tagsStore } from '@/store'
import Fuse from 'fuse.js'
import type { QSelect } from 'quasar'

interface SelectorOption {
  value: string | null;
  label: string;
}

@Component({ name: 'TagSelector' })
export default class TagSelector extends Vue {
  @Prop({
    type: Array,
    default: [],
  }) readonly value!: string[]

  fuseOptions = getDefaultFuseOptions(['label'])

  // bodgy bodge for our z-index values vs quasar default ones
  popupContentStyle: Partial<CSSStyleDeclaration> = { zIndex: '9999' }
  isLoading = false
  filteredOptions: SelectorOption[] = []

  @Ref() readonly selector!: QSelect

  get tags (): Tag[] {
    return tagsStore.state.tags
  }

  get noTagsLabel (): string {
    const key = 'common.withoutTags'
    // const options: i18next.TranslationOptions = { postProcess: 'capitalize' }
    return this.$t(key/*, options */).toString()
  }

  get placeholder (): string | null {
    return this.value.length === 0 ? this.noTagsLabel : null
  }

  get noTagsOption (): SelectorOption {
    return { value: null, label: this.noTagsLabel }
  }

  get options (): SelectorOption[] {
    const existingTagsOptions: SelectorOption[] = Object
      .values(this.tags)
      .map(t => ({ value: t.name, label: t.name }))
      .sort((a, b) => a.label > b.label ? 1 : -1)

    existingTagsOptions.push(this.noTagsOption)
    return existingTagsOptions
  }

  get fuse (): Fuse<SelectorOption> {
    return new Fuse(this.options, this.fuseOptions)
  }

  handleInput (v: Array<string | null>): void {
    let emitValue = v

    const clearSelection = v.some((v: string | null) => v === null)
    if (clearSelection) {
      emitValue = []
      this.selector.hidePopup()
    }

    this.$emit('input', emitValue)
  }

  searchFn (text: string): SelectorOption[] {
    return multiSearch((text: string) => this.fuse.search(text), text, 'value')
  }

  filterFn (userInput: string, update: (callback: () => void) => unknown): void {
    const val = userInput.trim()

    // filter only starting with second character
    update(() => {
      this.filteredOptions = val.length < 2
        ? this.options
        : this.searchFn(val)
    })
  }
}
